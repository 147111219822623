.unit-image {
    object-fit: cover !important;
    height: 300px;
    border-radius: 5px;
}
.unit-card {
    background-color: white;
    display: grid;
    grid-template-rows: 300px 60px 57px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    box-shadow: rgba(155, 162, 171, 0.15) 0px 0px 35px 0px;
    margin-bottom: 24px;
    grid-gap: 10px;

    &__image {
        grid-row: 1 / 2;
        grid-column: 1 / -1;
    }

    &__name {
        color: $ncassBlack;
        padding-left: 25px;
        grid-row: 2 / 3;
        grid-column: 1 / -1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
            margin-top: 15px;
        }
    }

    &__buttons {
        grid-row: 3 / 4;
        grid-column: 1 / -1;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 20px;
        margin-left: 25px;
        margin-right: 25px;
    }

    &__button {
        display: flex;
        flex-direction: row;
        &--large {
            flex-grow: 2;
            margin-right: 24px;
        }
    }
}

@media (max-width: 1400px) {

    .unit-card {
        grid-template-rows: 300px 60px min-content;
        &__button {
            margin-top: 5px;
            margin-bottom: 15px;

            button {
                min-width: 100px;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .unit-image {
        width: 75px;
        height: 75px;
        border-radius: 3px;
    }

    .unit-card {
        grid-template-rows: min-content min-content;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-bottom: 24px;
        grid-gap: 10px;
    
        &__image {
            grid-row: 1 / 2;
            grid-column: 1 / 2;
            margin-top: 10px;
            margin-left: 10px;
        }
    
        &__name {
            grid-row: 1 / 2;
            grid-column: 2 / -1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 0;
            padding-left: 5px;

            h3 {
                margin-top: 20px;
                text-align: center;
                margin-left: -10px;
            }
        }
    
        &__buttons {
            grid-row: 2 / 3;
            grid-column: 1 / -1;
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin-left: 10px;
            margin-right: 10px;
        }
    
        &__button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-grow: 1;

            &--large {
                flex-grow: 1;
                margin-right: 10px;
            }

            button {
                min-width: 20px;
                width: 100%;
            }
        }
    }

}