$ncassGreen:   #36A400;
$bookerOrange: #DB7909;
$calorRed: #e30711;
$ncassBlack: #3b3b3b;
$ncassWhite: #ffffff;
$ncassWhiteSmoke: #f5f5f5;
$ncassLightGray: #d3d3d3;

$errorRed: #AF0A0A;
$okayGreen: #36A400;
$warningAmber: #D97707;
$inProgressYellow: #E2A600;
$debugMagenta: #BB33AA;
$inactiveGrey: #979797;

$fadedGrey: #D3D3D3;
$darkTextColour: #2B2B2B;

$cashbackProgressBarDefaultBackground: #F2F2F2;

$closedPeriodBtnBackground: #1F2329;
$closedPeriodBackaground: $fadedGrey;
$createNewPeriodBorder: #707070;

$periodBlockTextColor: #323A47;

$bookerSignupButtonColor: #ec7928;
$bookerSignupBoxShadow: #00000029;
$bookerSignupButtonColorOnHover: #ec5c28;
$excludeRed: #C81010;